var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[(!_vm.loading)?_c('div',{staticClass:"container blueprint"},[_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('nav',{staticClass:"breadcrumb is-xlarge",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',{staticClass:"projectName"},[_c('router-link',{attrs:{"to":{
                path: '/bots/' + _vm.$route.params.bot,
              }}},[_vm._v(_vm._s(_vm.$route.params.bot)+"\n            ")])],1),_vm._v(" "),_c('li',{staticClass:"is-active",on:{"click":function($event){return _vm.copyBlueprint(_vm.$route.params.blueprint)}}},[_c('b-tooltip',{attrs:{"delay":500,"multilinedNO":"","position":"is-top","type":"is-dark","label":_vm.copied == _vm.$route.params.blueprint ? 'copied!' : 'copy function name'}},[_c('a',[_vm._v("\n                "+_vm._s(_vm.blueprint.name || _vm.defaultNames[_vm.blueprint.type] || _vm.blueprint.id)+"\n                 \n\n                "),_c('i',{staticClass:"fal",class:_vm.typeIcons[_vm.blueprint.type],staticStyle:{"opacity":"0.5"}})])])],1)])]),_vm._v(" "),_c('div',{staticClass:"columns is-mobile sec"},[_c('div',{staticClass:"column left"},[_c('p',[_c('router-link',{staticStyle:{"float":"left","padding-right":"5px"},attrs:{"to":{
                path: '/' + _vm.$route.params.bot + '/endpoints?method=' + 'get',
              },"active-class":"is-active","exact":""}},[_c('b-field',[_c('b-tag',{staticClass:"is-info",attrs:{"rounded":"","type":" is-light"}},[_vm._v("IMAGE GENERATOR "+_vm._s(_vm.blueprint.type))])],1)],1)],1),_vm._v(" "),_c('p',{on:{"click":function($event){return _vm.copyBlueprint(_vm.blueprint.path)}}},[_c('b-tooltip',{attrs:{"delay":500,"multilinedNO":"","position":"is-right","type":"is-dark","label":_vm.copied == _vm.blueprint.path ? 'copied!' : 'copy path'}},[_c('span',{staticStyle:{"opacity":"0.8","margin-right":"10px","margin-left":"10px"}},[_vm._v("\n                "+_vm._s(_vm.blueprint.desc)+"\n              ")])])],1)]),_vm._v(" "),_c('div',{staticClass:"column is-narrow right"},[_c('b-button',{attrs:{"disabled":"","outlined":"","rounded":"","label":"41M runs","type":"is-dark","icon-pack":"far","icon-left":"  fa-rocket"}}),_vm._v(" "),(_vm.blueprint.type == 'image')?_c('router-link',{attrs:{"to":'/images/' + _vm.$route.params.bot + '?template=' + _vm.$route.params.blueprint}},[_c('b-button',{attrs:{"outlined":"","rounded":"","label":"Create images","type":"is-dark","icon-pack":"far","icon-left":"  fa-sparkles"}})],1):_vm._e(),_vm._v(" "),(_vm.blueprint.type == 'chat')?_c('router-link',{attrs:{"to":'/messages/' + _vm.$route.params.bot + '/new/' + _vm.$route.params.blueprint}},[_c('b-button',{attrs:{"outlined":"","rounded":"","label":"Message","type":"is-dark","icon-pack":"far","icon-left":"  fa-comment"}})],1):_vm._e()],1)]),_vm._v(" "),_c('br',{staticStyle:{"clear":"both"}}),_vm._v(" "),_c('div',{staticClass:"tabs"},[_c('ul',_vm._l((_vm.tabs),function(tab,index){return _c('router-link',{key:index,attrs:{"tag":"li","to":{
              path: _vm.baseUrl + tab.path,
            },"active-class":"is-active","exact":""}},[_c('a',[_c('span',{staticClass:"icon is-small"},[_c('i',{class:tab.icon,attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_c('span',[_vm._v(_vm._s(tab.title))])])])}),1)]),_vm._v(" "),(_vm.blueprint)?_c('div',[_c('router-view',{attrs:{"blueprint":_vm.blueprint,"blueprints":_vm.blueprints,"bot":_vm.bot},on:{"save":_vm.save}}),_vm._v(" "),_c('json-debug',{attrs:{"data":_vm.blueprint,"label":"Blueprint data"}})],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('br')])
}
var staticRenderFns = []

export { render, staticRenderFns }