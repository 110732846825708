<template>
  <div class="section">
    <div class="container blueprint" v-if="!loading">
      <br />
      <br />
      <br />
      <nav class="breadcrumb is-xlarge" aria-label="breadcrumbs">
        <ul>
          <li class="projectName">
            <router-link
              :to="{
                path: '/bots/' + $route.params.bot,
              }"
              >{{ $route.params.bot }}
            </router-link>
          </li>
          <li class="is-active" @click="copyBlueprint($route.params.blueprint)">
            <b-tooltip
              :delay="500"
              multilinedNO
              position="is-top"
              type="is-dark"
              :label="copied == $route.params.blueprint ? 'copied!' : 'copy function name'"
            >
              <a>
                {{ blueprint.name || defaultNames[blueprint.type] || blueprint.id }}
                &nbsp;

                <i class="fal" :class="typeIcons[blueprint.type]" style="opacity: 0.5"></i>
              </a>
            </b-tooltip>
          </li>
        </ul>
      </nav>

      <div class="columns is-mobile sec">
        <div class="column left">
          <p>
            <router-link
              :to="{
                path: '/' + $route.params.bot + '/endpoints?method=' + 'get',
              }"
              active-class="is-active"
              exact
              style="float: left; padding-right: 5px"
            >
              <b-field>
                <b-tag rounded class="is-info" type=" is-light">IMAGE GENERATOR {{ blueprint.type }}</b-tag>
              </b-field>
            </router-link>
          </p>
          <p @click="copyBlueprint(blueprint.path)">
            <b-tooltip
              :delay="500"
              multilinedNO
              position="is-right"
              type="is-dark"
              :label="copied == blueprint.path ? 'copied!' : 'copy path'"
            >
              <span style="opacity: 0.8; margin-right: 10px; margin-left: 10px">
                <!-- 


Posting 3 times a day automatically

            
                Roleplay as sponge bob -->
                {{ blueprint.desc }}
              </span>
            </b-tooltip>
          </p>
        </div>

        <div class="column is-narrow right">
          <!--  
          <b-button outlined rounded label=" About" type="is-dark" icon-pack="far" icon-left="  fa-pencil" />
          -->
          <b-button disabled outlined rounded label="41M runs" type="is-dark" icon-pack="far" icon-left="  fa-rocket" />

          <!-- 
          <router-link
            v-if="blueprint.type == 'image'"
            :to="'/images/create?bot=' + $route.params.bot + '&template=' + $route.params.blueprint"
          >
            <b-button outlined rounded label="OLDD image" type="is-dark" icon-pack="far" icon-left="  fa-sparkles" />
          </router-link>
           -->

          <router-link
            v-if="blueprint.type == 'image'"
            :to="'/images/' + $route.params.bot + '?template=' + $route.params.blueprint"
          >
            <b-button outlined rounded label="Create images" type="is-dark" icon-pack="far" icon-left="  fa-sparkles" />
          </router-link>

          <router-link
            v-if="blueprint.type == 'chat'"
            :to="'/messages/' + $route.params.bot + '/new/' + $route.params.blueprint"
          >
            <b-button outlined rounded label="Message" type="is-dark" icon-pack="far" icon-left="  fa-comment" />
          </router-link>
        </div>
      </div>

      <br style="clear: both" />

      <div class="tabs">
        <ul>
          <router-link
            tag="li"
            v-for="(tab, index) in tabs"
            :key="index"
            :to="{
              path: baseUrl + tab.path,
            }"
            active-class="is-active"
            exact
          >
            <a>
              <span class="icon is-small">
                <i :class="tab.icon" aria-hidden="true"></i>
              </span>
              <span>{{ tab.title }}</span>
            </a>
          </router-link>
        </ul>
      </div>

      <div v-if="blueprint">
        <router-view :blueprint="blueprint" :blueprints="blueprints" :bot="bot" @save="save"></router-view>
        <json-debug :data="blueprint" label="Blueprint data" />
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import { MethodColors } from "@/common/utils.js";

import jsonDebug from "../components/e/jsonDebug.vue";
import { blueprintTypes, typeIcons, defaultNames } from "@/data/blueprintTypes.js";

const TABS = [
  /*
     {
      path: "",
      icon: "far fa-tachometer",
      title: "Overview",
    } 
    {
      path: "/use",
      icon: "far fa-envelope",
      title: "Messages",
    },
    
      {
      path: "/config",
      icon: "far fa-calendar",
      title: "Calendar?",
      isSystem: false,
    },
    
    */
  /*
    {
      path: "/insights",
      icon: "far fa-search",
      title: "Search results",
      isSystem: false,
    },*/

  //

  {
    path: "/logs",
    icon: "far fa-play",
    title: "Demo",
    isSystem: false,
  },
  {
    path: "/",
    icon: "far fa-sliders-h",
    title: "Options",
    isSystem: false,
  },

  {
    path: "/code",
    icon: "far fa-history",
    title: "History",
    isSystem: false,
  },
];
export default {
  components: { jsonDebug },
  name: "Home",
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      blueprint: { type: "chat", chatExamples: [] }, //!! TEMPP
      loadingBlueprint: true,
      blueprints: [],

      loading: true,
      MethodColors,
      copied: null,
      bot: null,
      blueprintTypes,
      typeIcons,
      defaultNames,
    };
  },
  computed: {
    title() {
      return this.$route.params.blueprint;
    },
    baseUrl() {
      return "/bots/" + this.$route.params.bot + "/" + this.$route.params.blueprint;
      return "/" + this.$route.params.bot + "/manage/functions/" + this.$route.params.blueprint;
    },
    tabs() {
      var b = this.blueprint;
      //if (!b.type) return [];
      let tabs = [];

      if (b.type == "chat") {
        tabs = [
          {
            path: "/",
            icon: "far fa-sliders-h",
            title: "Options",
            isSystem: false,
          },
          {
            title: "Demo",
            path: "/message-demo",
            icon: "far fa-play",
          },

          {
            title: "Examples",
            path: "/chat-examples",
            icon: "far fa-comments",

            isSystem: false,
          },
        ];
      } else if (b.type == "image") {
        tabs = [
          {
            path: "/",
            icon: "far fa-sliders-h",
            title: "Options",
            isSystem: false,
          },
          {
            title: "Demo",
            path: "/image-demo",
            icon: "far fa-play",
          },

          {
            title: "Generated",
            path: "/user-generated",
            icon: "far fa-magic",

            isSystem: false,
          },
        ];
      } else {
        tabs = [
          {
            path: "/logs",
            icon: "far fa-play",
            title: "Demo2",
            isSystem: false,
          },
          {
            path: "/config",
            icon: "far fa-sliders-h",
            title: "Options",
            isSystem: false,
          },

          {
            path: "/code",
            icon: "far fa-history",
            title: "History",
            isSystem: false,
          },
        ];
      }

      return tabs;

      if (this.blueprint.isSystem) {
        // If the blueprint is system, return all tabs
        return TABS;
      } else {
        // If not, return only the tabs that are not system-specific
        return TABS.filter((tab) => !tab.isSystem);
      }

      return TABS;
    },
  },
  methods: {
    loadAll(opt) {
      window.API.botProfileOwner(this.$route.params.bot, opt).then((bot) => {
        this.bot = bot;
        this.loadingBot = false;
        console.log("BOT!", bot, bot.id);
      });

      var id = this.$route.params.blueprint;

      window.API.getBotBlueprintOwner(this.$route.params.bot, id, opt).then((a) => {
        this.blueprint = a;
        this.loading = false;
        //  console.log("BOT!", bot, bot.id);
      });

      /*
        window.API.getBotBlueprints(this.$route.params.bot, opt).then((blueprints) => {
          var blueprintId = this.$route.params.blueprint;
          this.blueprints = blueprints;
          this.loadingBlueprints = false;
          this.blueprint = blueprints.filter((i) => i.blueprintId == blueprintId)[0];
  
          if (!this.blueprint) {
            this.blueprint = systemEndpoints.filter((i) => i.blueprintId == blueprintId)[0];
            if (this.blueprint) {
              this.blueprint.isSystem = true;
            }
          }
        });*/
    },

    save() {
      //alert("saving! 15684 TODO");
      window.API.editBotBlueprint(this.$route.params.bot, this.blueprint.id, this.blueprint).then((res) => {
        this.$buefy.toast.open({
          message: "Function updated successfully!",
          type: "is-warning",
          position: "is-bottom",
          duration: 5000,
          queue: false,
        });
        //go previous page in history.
        this.$router.go(-1);
      });

      // console.log("REFRESH GENERATOR data.");
      //this.loadAll({ noCache: 1 });
    },
    copyBlueprint(txt) {
      navigator.clipboard.writeText(txt);
      this.copied = txt;

      setTimeout(
        function () {
          this.copied = null;
          console.log("reseted clipboard!");
        }.bind(this),
        6000
      );
    },
  },
  mounted() {
    this.loadAll({ noCache: 1 });
  },
};
</script>
<style scoped>
.sec.columns {
  margin: 0;
  align-items: flex-end;
}

.sec.columns .column {
  padding: 0;
  padding-bottom: 20px;
}
.breadcrumb.is-xlarge {
  margin-bottom: 0;
}
</style>
